import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useGuides from 'content-queries/mx/new/guides'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { MainBanner, MainContent } from 'mx/components/new'
import Layout from 'mx/layout'

const GuidesPage = ({ path }: { path: string }) => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Guides',
    })
  }, [])

  const { data } = useGuides()

  const [
    {
      node: {
        sectionModules: [hero, inspiration, , glossary, why, banner],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <MainContent header data={hero} />
      <MainContent data={inspiration} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={glossary} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={why} inline classname={tw`mt-20 lg:mt-32`} />
      <MainBanner data={banner} classname={tw`mt-20 lg:mt-32`} />
    </Layout>
  )
}

export default GuidesPage

export const Head = () => {
  const { data } = useGuides()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/guides"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
